import {API} from '../../config/api'

export default async (data) => {
    try{
        const responce = await fetch(`${API}/login`,{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify(data)
        })

        const {success,admin,token} = await responce.json()
        localStorage.setItem('flyBusToken',token)
        return {success,admin}
    }catch(error){
        console.log(error)
        return {success:false,admin:{}}
    }
}