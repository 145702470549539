import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from '../../styles/core/text-editor.module.scss'

const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
}
const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
]

const TextEditor = ({title,value,handler,name}) => {

  return (
        <div className={styles.body}>
            <div className={styles.title}>
                {title}
            </div>
            <ReactQuill 
            modules={modules}
            formats={formats}
            className={styles.editor}
            theme="snow" 
            value={value} 
            onChange={(v) => handler(name,v)} />
        </div>
  )
}

export default TextEditor
