import { AiOutlineSearch } from 'react-icons/ai'
import styles from '../../styles/core/search.module.scss'

const SearchBar = ({value,onChange,placeholder}) => {
  return (
    <div className={styles.body}>
        <AiOutlineSearch/>
        <input 
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="text"/>
    </div>
  )
}

export default SearchBar
