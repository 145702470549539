import {API} from '../../config/api'
import getToken from '../../utils/getToken'

export default async (data) => {
    try{
        const token = getToken()

        const responce = await fetch(`${API}/info`,{
            method:'PUT',
            headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type':'application/json',
            },
            body:JSON.stringify(data)
        })

        const {success,info} = await responce.json()

        return {success,info}

    }catch(error){
        console.log(error)
        return {success:false}
    }
}