import UsersList from "../components/usersList/UsersList"

const Users = () => {
  return (
    <div className='wrapper'>
        <h1>Пользователи</h1>
        <UsersList/>
    </div>
  )
}

export default Users