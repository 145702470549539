import { useNavigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import { AiOutlineUser,AiOutlineBell } from 'react-icons/ai'
import { IoExitOutline } from 'react-icons/io5'
import { GiHamburgerMenu } from "react-icons/gi";
import { changeLang } from '../../store/slices/langSlice'
import { LANG_ENG, LANG_RUS, LANG_UKR } from '../../config/data'
import { openModal } from '../../store/slices/modalsSlice';
import loader from '../../config/loader'
import styles from '../../styles/layout/header.module.scss'

const Header = ({newApplicationsValue,confirmLogout}) => {
  const router = useNavigate()
  const dispatch = useDispatch()
  const currentLang = useSelector((state) => state.lang.lang)
  const admin = useSelector((state) => state.auth.admin)

  return (
    <div className={styles.body}>
        <div className={styles.user}>
          {
            admin?.img 
            ?
            <div className={styles.userImg + ' ' + styles.img}>
              <img 
              src={loader(admin.img)} 
              alt={admin.username} 
              />
            </div>
            :
            <div className={styles.userImg}>
              <AiOutlineUser/>
            </div>
          }

            <div className={styles.userInfo}>
              <div className={styles.userName}>
                  {admin.username}
              </div>
              <div className={styles.userStatus}>
                  {admin.role}
              </div>
            </div>
        </div>

        <div className={styles.btns}>

          <div className={styles.langs}>
            <button
            onClick={() => dispatch(changeLang(LANG_ENG))}
            className={
              currentLang === LANG_ENG
              ?
              styles.lang + ' ' + styles.current 
              :
              styles.lang
            }
            >
              АНГЛ
            </button>
            <span>|</span>
            <button
            onClick={() => dispatch(changeLang(LANG_UKR))}
            className={
              currentLang === LANG_UKR
              ?
              styles.lang + ' ' + styles.current 
              :
              styles.lang
            }
            >
              УКР
            </button>

          </div>
          <button 
          onClick={() => router('/applications')}
          className={styles.ballWrapper}>
            <span 
            className={styles.ball}>
              <AiOutlineBell/>
            </span>
            {
              newApplicationsValue > 0 
              ?
              <div className={styles.applicationsValue}>
                {newApplicationsValue}
              </div>
              :
              <></>
            }
          </button>
          <button 
          onClick={confirmLogout}
          className={styles.exit}>
            <IoExitOutline/>
          </button> 
          <button 
          onClick={() => dispatch(openModal('mobileNav'))}
          className={styles.mobileBtn}>
            <GiHamburgerMenu/>
        </button>
        </div>
    </div>
  )
}

export default Header
