import { useState, useEffect, useMemo } from 'react'
import { AiOutlineUser, AiOutlineDelete} from 'react-icons/ai'
import { useSelector } from 'react-redux'
import createAdmin from '../../services/admin/createAdmin'
import getAdmins from '../../services/admin/getAdmins'
import deleteAdmin from '../../services/admin/deleteAdmin'
import Modal from '../../core/Modal/Modal'
import ConfirmModal from '../../core/confirmModal/ConfirmModal'
import FileInput from '../../UI/inputs/FileInput'
import modalHandler from '../../utils/modalHandler'
import MainButton from '../../UI/buttons/MainButton'
import MainInput from '../../UI/inputs/MainInput'
import inputsHandler from '../../utils/inputsHandler'
import LoaderCustom from '../../core/loader/Loader'
import loader from '../../config/loader'
import styles from '../../styles/admins.module.scss'

const adminsInitial = [
    {
        name:'Admin',
        status:'admin',
    },
    {
        name:'Admin',
        status:'admin',
    },
    {
        name:'Admin',
        status:'admin',
    },
    {
        name:'Admin',
        status:'admin',
    },
]

const Admins = () => {
    const [admins,setAdmins] = useState(adminsInitial)
    const [loading,setLoading] = useState(false)
    const [isCreateModal,setIsCreateModal] = useState(false)
    const [isDeleteModal,setIsDeleteModal] = useState(false)
    const [adminToDelete,setAdminToDelete] = useState(null)
    const [data,setData] = useState({username:'',password:'',confirm:'',img:'',role:''})
    const [isMainAdmin,setIsMainAdmin] = useState(false)
    const admin = useSelector((state) => state.auth.admin)
    
    const selectAdminToDelete = (admin) => {
        setAdminToDelete(admin)
        setIsDeleteModal(true)
    }

    const confirmCreate = async () => {
        setLoading(true)
        const formData = new FormData()
        if(data.img){
            formData.append('img', data.img);
        }
        formData.append('username', data.username);
        formData.append('password', data.password);
        formData.append('role', data.role);

        const {success,admin} = await createAdmin(formData)

        if(success){
            setAdmins((prev) => [...prev,admin])
            setIsCreateModal(false)
        }
        setLoading(false)
    }

    const confirmDelete = async () => {
        setLoading(true)
        const {success} = await deleteAdmin(adminToDelete.username)

        if(success){
            setIsDeleteModal(false)
            setAdmins((prev) => {
                return prev.filter((admin) => admin.username !== adminToDelete.username)
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        getAdmins().then(({admins}) => {
            setAdmins(admins)
            setLoading(false)
        })
        setIsMainAdmin(admin?.username === 'admin')
    },[])
    
    const isValid = useMemo(() => {
        return data.password === data.confirm && data.password.length
    },[data])
  
    if(loading) return <LoaderCustom/>

  return (
    <>
        <div className={styles.body}>
        <div className={'title-main'}>
            Администраторы:
        </div>
        <div className={styles.list}>
            {
                admins.map((admin,index) => {
                    return (
                        <div key={index} className={styles.admin}>
                            <div className={styles.img}>
                                {
                                    admin.img 
                                    ?
                                    <img
                                    src={loader(admin.img)}
                                    alt={admin.username}
                                    />
                                    :
                                    <AiOutlineUser/>
                                }
                            </div>
                            <div className={styles.info}>
                                <div className={styles.name}>
                                    {
                                        admin.username
                                    }
                                </div>
                                <div className={styles.status}>
                                    {
                                        admin.role
                                    }
                                </div>
                            </div>
                            {
                                isMainAdmin
                                ?
                                <button
                                onClick={() => selectAdminToDelete(admin)}
                                className={styles.delete}>
                                    <AiOutlineDelete/>
                                </button>
                                :
                                <></>
                            }
                        </div>
                    )
                })
            }
            {
                isMainAdmin
                ?
                <MainButton
                onClick={() => setIsCreateModal(true)}
                text={'Добавить администратора'}
                />
                :
                <></>
            }

        </div>
    </div>
    <Modal
    title='Добавить администратора'
    handler={(e) => modalHandler(e,setIsCreateModal)}
    isVisible={isCreateModal}
    >
        <div className={styles.createAdminBody}>
            <MainInput
            value={data.username}
            onChange={(name,value) => inputsHandler(name,value,setData)}
            name='username'
            label='Логин:'
            placeholder='username'
            />
            <MainInput
            type='password'
            value={data.password}
            onChange={(name,value) => inputsHandler(name,value,setData)}
            name='password'
            label='Пароль:'
            placeholder='********'
            />
            <MainInput
            type='password'
            value={data.confirm}
            onChange={(name,value) => inputsHandler(name,value,setData)}
            name='confirm'
            label='Подтверждение пароля:'
            placeholder='********'
            />
            <MainInput
            value={data.role}
            onChange={(name,value) => inputsHandler(name,value,setData)}
            name='role'
            label='Роль:'
            placeholder='Менеджер'
            />
            <FileInput
            label={'Фото:'}
            name={'img'}
            file={data.img}
            handler={(name,file) => inputsHandler(name,file,setData)} 
            />
            <MainButton
            disabled={!isValid}
            onClick={confirmCreate}
            text={'Подтвердить'}
            />
        </div>
    </Modal>
    <ConfirmModal
    onClick={confirmDelete}
    value={adminToDelete}
    modalHandler={(e) => modalHandler(e,setIsDeleteModal)}
    isVisible={isDeleteModal}
    title={`Удаление аккаунта ${adminToDelete?.username}`}
    />
    </>
  )
}

export default Admins
