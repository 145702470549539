import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import updateReview from "../../services/reviews/updateReview"
import createReview from "../../services/reviews/createReview"
import getReviews from "../../services/reviews/getReviews"
import deleteReview from "../../services/reviews/deleteReview"
import FileInput from '../../UI/inputs/FileInput'
import MainButton from "../../UI/buttons/MainButton"
import MainInput from "../../UI/inputs/MainInput"
import inputsHandler from "../../utils/inputsHandler"
import LoaderCustom from "../../core/loader/Loader"
import styles from '../../styles/reviews.module.scss'

const Reviews = () => {
    const {lang} = useSelector((state) => state.lang)
    const [loading,setLoading] = useState(false)
    const [items,setItems] = useState([])
    const [isNewReview,setIsNewReview] = useState(false)
    const [newReview,setNewReview] = useState(() => (
        {
            img:'',
            name:"",
            text:"",
            date:'',
            stars:5,
        }
    ))

    const confirmUpdateReview = async (id,reviewToUpdate) => {
        setLoading(true)
        const formData = new FormData()
        if(reviewToUpdate.img && typeof reviewToUpdate.img !== 'string'){
            formData.append('img', reviewToUpdate.img);
        }
        formData.append('name', reviewToUpdate.name);
        formData.append('text', reviewToUpdate.text);
        formData.append('date', reviewToUpdate.date);
        formData.append('stars', reviewToUpdate.stars);
        formData.append('lang', lang);

        await updateReview(id,formData)

        setLoading(false)
    }

    const confirmCreateReview = async () => {
        setLoading(true)
        const formData = new FormData()
        if(newReview.img && typeof newReview.img !== 'string'){
            formData.append('img', newReview.img);
        }
        formData.append('name', newReview.name);
        formData.append('text', newReview.text);
        formData.append('date', newReview.date);
        formData.append('stars', newReview.stars);
        formData.append('lang', lang);

        const {success,review} = await createReview(formData)

        if(success){
            setItems((prev) => [review,...prev])
            setNewReview({
                img:'',
                name:"",
                text:"",
                date:'',
                stars:5,
            })
            setIsNewReview(false)
        }

        setLoading(false)
    }
        
    const confrimDeleteReview = async (id) => {
        setLoading(true)
        const {success} = await deleteReview(id)

        if(success){
            setItems((prev) => {
                return (
                    prev.filter((rev) => {
                        return rev._id !== id
                    })
                )
            })
        }

        setLoading(false)
    }

    const reviewsHandler = (name,value,index) => {
        setItems((prev) => {
            return (
                    prev.map((item,i) => {
                        if(i === index){
                            return (
                                {
                                    ...item,
                                    [name]:value
                                }
                            )
                        }
                        return item
                    })
            )
        })
    }

    useEffect(() => {
        setLoading(true)
        getReviews(lang).then(({success,reviews}) => {
            if(success){
                console.log(reviews)
                setItems(reviews)
            }
            setLoading(false)
        })
    },[])

    if(loading) return <LoaderCustom/>

  return (
    <div className={styles.body}>
        <div className={styles.title}>
            Отзывы:
        </div>
        <div className={styles.items}>
            {
                isNewReview
                ?
                <div className={styles.item}>
                <FileInput
                label={'Фото:'}
                file={newReview.img}
                name={'img'}
                handler={(name,value) => inputsHandler(name,value,setNewReview)}
                />
                <MainInput
                placeholder="Имя"
                onChange={(name,value) => inputsHandler(name,value,setNewReview)}
                name="name"
                label="Имя:"
                value={newReview.name}
                />
                <MainInput
                placeholder="Текст..."
                onChange={(name,value) => inputsHandler(name,value,setNewReview)}
                name="text"
                label="Отзыв:"
                value={newReview.text}
                />
                <MainInput
                placeholder="5"
                type="number"
                onChange={(name,value) => inputsHandler(name,value,setNewReview)}
                name="stars"
                label="Кол-во звезд:"
                value={newReview.stars}
                />
                <MainInput
                type="text"
                onChange={(name,value) => inputsHandler(name,value,setNewReview)}
                name="date"
                label="Дата:"
                placeholder="15.10.2023"
                value={newReview.date}
                />
                <MainButton
                onClick={confirmCreateReview}
                text={'Добавить'}
                />
                <MainButton
                type="red"
                onClick={() => setIsNewReview(false)}
                text={'Отменить'}
                />
                </div> 
                :
                <div className={styles.addBtnWrapper}>
                    <button 
                    onClick={() => setIsNewReview((prev) => !prev)}
                    className={styles.addBtn}>
                    +
                    </button>
                </div>   
            }
         
        {
            items.map((review,index) => {
                return (
                    <div className={styles.item} key={index}>
                        <FileInput
                        label={'Фото:'}
                        file={review.img}
                        name={'img'}
                        handler={(name,value) => reviewsHandler(name,value,index)}
                        />
                        <MainInput
                        placeholder="Имя"
                        onChange={(name,value) => reviewsHandler(name,value,index)}
                        name="name"
                        label="Имя:"
                        value={review.name}
                        />
                        <MainInput
                        placeholder="Текст..."
                        onChange={(name,value) => reviewsHandler(name,value,index)}
                        name="text"
                        label="Отзыв:"
                        value={review.text}
                        />
                        <MainInput
                        placeholder="5"
                        type="number"
                        onChange={(name,value) => reviewsHandler(name,value,index)}
                        name="stars"
                        label="Кол-во звезд:"
                        value={review.stars}
                        />
                        <MainInput
                        type="text"
                        onChange={(name,value) => reviewsHandler(name,value,index)}
                        name="date"
                        label="Дата:"
                        placeholder="15.10.2023"
                        value={review.date}
                        />
                        <MainButton
                        onClick={() => confirmUpdateReview(review._id,review)}
                        text={'Сохранить'}
                        />
                        <MainButton
                        onClick={() => confrimDeleteReview(review._id)}
                        type="red"
                        text={'Удалить'}
                        />
                    </div>
                )
            })
        }
        </div>
    </div>
  )
}

export default Reviews
