import {API} from '../../config/api'
import getToken from '../../utils/getToken'

export default async () => {
    try{
        const responce = await fetch(`${API}/info`)

        const {success,info} = await responce.json()

        return {success,info}

    }catch(error){
        console.log(error)
        return {success:false}
    }
}