import TicketsList from '../components/ticketsList/TicketsList'
import styles from '../styles/tickets/tickets.module.scss'

const Tickets = () => {
  return (
    <div className={'wrapper' + ' ' + styles.body}>
        <h1>Маршруты</h1>
        <div className={styles.list}>
            <TicketsList/>
        </div>
    </div>
  )
}

export default Tickets
