import styles from '../../styles/core/custom-toggle.module.scss'

export const CustomToggle = ({value,handler,firstItem,secondItem}) => {
  return (
    <div className={styles.body}>
        <button
        onClick={handler}
        className={
          !value 
          ?
          styles.selected
          :
          <></>
        }
        >
            {firstItem}
        </button>
        <button
        onClick={handler}
        className={
          value 
          ?
          styles.selected
          :
          <></>
        }
        >
            {secondItem}
        </button>
    </div>
  )
}
