import getToken from "../../utils/getToken"
import { API } from "../../config/api"

export default async (ticket,application) => {
    try{
        const token = getToken()

        const response = await fetch(`${API}/ticket/${ticket}/${application}`,{
            method:'GET',
            headers:{
                'Authorization': `Bearer ${token}`
            },
        })

        const {success,ticketPdf} = await response.json()

        return {success,ticketPdf}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}