import { API } from "../../config/api"
import getToken from "../../utils/getToken"

export default async (email) => {
    try{
        const token = getToken()

        const response = await fetch(`${API}/users/${email}`,{
            method:'PATCH',
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })

        const {success,user} = await response.json()

        return {success,user}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}