import Modal from '../Modal/Modal'
import MainButton from '../../UI/buttons/MainButton'
import styles from '../../styles/core/confirm.module.scss'

const ConfirmModal = ({onClick,title,modalHandler,isVisible,value}) => {
  return (
    <Modal
    isVisible={isVisible}
    handler={modalHandler}
    title={title}
    >
        <div className={styles.body}>
            <MainButton
            btnId='toggle-modal'
            type='red'
            text={'Отменить'}
            />
            <MainButton
            onClick={onClick}
            text={'Подтвердить'}
            />
        </div>
    </Modal>
  )
}

export default ConfirmModal
