import getToken from "../../utils/getToken"
import { API } from "../../config/api"

export default async (params) => {
    try{
        let searchParams = ''

        for (const key in params) {
            const param = params[key];

            searchParams = searchParams + `/${param || 'none'}`
        }
        
        const response = await fetch(`${API}/tickets${searchParams}`)

        const {success,tickets} = await response.json()

        return {success,tickets}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}