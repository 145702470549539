import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { store } from './store/store'
import { Provider } from 'react-redux'
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Applications from './pages/Applications';
import Tickets from './pages/Tickets';
import Content from './pages/Content';
import Users from './pages/Users'
import './index.css';

const router = createBrowserRouter([
  {
    path:'/',
    element:<Layout/>,
    children:[
      {
        element:<Home/>,
        index:true
      },
      {
        element:<Applications/>,
        path:'/applications'
      },
      {
        element:<Tickets/>,
        path:'/tickets'
      },
      {
        element:<Users/>,
        path:'/users'
      },
      {
        element:<Content/>,
        path:'/content'
      },
    ]
  },
  {
    path:'/login',
    element:<Login/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}/>
    </Provider>
  </React.StrictMode>
);

