import getToken from "../../utils/getToken"
import { API } from "../../config/api"

export default async (isConfirmedApp) => {
    try{
        const token = getToken()

        const response = await fetch(`${API}/applications/${isConfirmedApp}`,{
            method:'GET',
            headers:{
                'Authorization': `Bearer ${token}`
            },
        })

        const {success,applications} = await response.json()

        return {success,applications}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}