import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Outlet } from "react-router"
import { useDispatch,useSelector } from "react-redux"
import { setAdmin } from "../../store/slices/authSlice"
import { closeModal } from "../../store/slices/modalsSlice"
import session from '../../services/auth/session'
import Header from "./Header"
import Nav from "./Nav"
import LoaderCustom from "../../core/loader/Loader"
import getApplications from '../../services/applications/getApplications'
import MobileNav from "./MobileNav"
import logout from '../../services/auth/logout'
import styles from '../../styles/layout/layout.module.scss'

const Layout = () => {
  const [newApplicationsValue,setNewApplicationsValue] = useState(0) 
  const [loading,setLoading] = useState(true)
  const dispatch = useDispatch()
  const router = useNavigate()
  const isMobileNav = useSelector((state) => state.modals.mobileNav)

  const mobileModalHandler = (e) => {
    if(e.target.id === 'toggle-modal'){
      dispatch(closeModal('mobileNav'))
    }
  }
  
  const confirmLogout = () => {
    logout()
    router('/login')
  }

  useEffect(() => {
    const checkAuth = async () => {
      const {success,admin} = await session()
      dispatch(setAdmin(admin))
      if(!success){
        router('/login')
      }else{
        setLoading(false)
      }
    }
    const getNewApps = async () => {
      const {success,applications} = await getApplications(false)

      success && setNewApplicationsValue(applications.length)
    }
    checkAuth()
    getNewApps()
  },[])

  if(loading) return <LoaderCustom/>

  return (
    <div className={styles.body}>
        <MobileNav 
        logout={confirmLogout}
        handler={mobileModalHandler}
        isVisible={isMobileNav.state}/>
        <Nav/>
        <div className={styles.page}>
          <Header
          confirmLogout={confirmLogout}
          newApplicationsValue={newApplicationsValue}
          />
          <Outlet></Outlet>
        </div>
    </div>
  )
}

export default Layout
