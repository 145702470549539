import ApplicationList from '../components/applicationsList/ApplicationList'
import styles from '../styles/applications/applications.module.scss'

const Applications = () => {
  return (
    <div className={styles.body + ' ' + 'wrapper'}>
        <h1>
            Заявки
        </h1>
        <ApplicationList/>
    </div>
  )
}

export default Applications
