import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import langSlice from './slices/langSlice'
import modalsSlice from './slices/modalsSlice'

export const store = configureStore({
  reducer: {
    auth:authSlice,
    lang:langSlice,
    modals:modalsSlice
  },
})
