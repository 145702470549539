import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai'
import { FiDatabase } from 'react-icons/fi'
import { BsTicketPerforated } from 'react-icons/bs'
import { BiBookContent } from 'react-icons/bi'
import {useLocation,Link} from 'react-router-dom'
import styles from '../../styles/layout/nav.module.scss'

export const links = [
    {
        name:'Главная',
        href:'/',
        icon:<AiOutlineHome/>
    },
    {
        name:'Заявки',
        href:'/applications',
        icon:<FiDatabase/>
    },
    {
        name:'Маршруты',
        href:'/tickets',
        icon:<BsTicketPerforated/>
    },
    {
        name:'Пользователи',
        href:'/users',
        icon:<AiOutlineUser/>
    },
    {
        name:'Контент',
        href:'/content',
        icon:<BiBookContent/>
    },
]

const Nav = () => {
    const location = useLocation()
    
  return (
    <nav className={styles.body}>
        <div className={styles.head}>
            <div className={styles.logo}>
                <a href='#'>
                    FLYBUS
                </a>
            </div>
            <div className={styles.label}>
                Админ панель
            </div>
        </div>
        <div className={styles.links}>
            {
                links.map((link,index) => {
                    return (
                        <Link 
                        key={index}
                        className={
                            location.pathname === link.href
                            ?
                            styles.current
                            :
                            styles.link
                        } to={link.href}>
                            {link.icon}
                            {link.name}
                        </Link>
                    )
                })
            }
        </div>
    </nav>
  )
}

export default Nav
