import { useState } from 'react'
import { LANG_ENG } from '../../config/data'
import { FiDownload } from "react-icons/fi";
import loader from '../../config/loader'
import LoaderCustom from '../../core/loader/Loader'
import confirmApplication from '../../services/applications/confirmApplication'
import MainBtn from '../../UI/buttons/MainButton'
import ActionBtn from '../../UI/buttons/ActionButton'
import downloadTicket from '../../services/tickets/downloadTicket';
import changeDateType from '../../utils/changeDateType'
import styles from '../../styles/applications/item.module.scss'

export const ApplicationItem = ({application,cancelApplication,filterApplication}) => {
    const [loading,setLoading] = useState(false)
    const [dLink,setDLink] = useState('')

    const confirmItem = async () => {
        setLoading(true)

        const {success} = await confirmApplication(application._id)

        if(success){
            filterApplication(application)
        }

        setLoading(false)
    }

    const confirmDownloadTicket = async () => {
        const tickeId = application.ticketId
        const applicationId = application._id
        setLoading(true)
        const {success,ticketPdf} = await downloadTicket(tickeId,applicationId)
        setLoading(false)
        if(success){
            setDLink(loader(ticketPdf))
            window.open(loader(ticketPdf))
        }
    }
    console.log(application)
    if(loading) return <LoaderCustom/>

  return (
    <div className={
        application.isConfirm 
        ?
        styles.body + ' ' + styles.confirmed 
        :
        styles.body
    }>
        <div className={styles.ticketNumber}>
            №{application.ticketNumber || '-'}
        </div>
        <div className={styles.row}> 
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Маршрут/время:
            </div>
            <div className={styles.values}>
                <div className={styles.value}>
                    {application.currentTicketData?.from} - {application.currentTicketData?.to} 
                </div>
                <div className={styles.value}>
                    {application.currentTicketData?.startTime} - {application.currentTicketData?.endTime} 
                </div>
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Дата отправки:
            </div>
            <div className={styles.value}>
                {new Date(application.date).toLocaleDateString()}
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Дата создания заявки:
            </div>
            <div className={styles.value}>
                {changeDateType(new Date(application.createDate),1)}
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Email:
            </div>
            <div className={styles.value}>
                {application.email}
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Телефон:
            </div>
            <div className={styles.value}>
                {application.phone || '-'}
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Цена билета:
            </div>
            <div className={styles.value}>
                <span>{application.currentTicketData.price} </span> 
                {
                    application.currentTicketData?.lang === LANG_ENG
                    ?
                    '€'
                    :
                    'грн'
                }
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Общая сумма:
            </div>
            <div className={styles.value}>
                <span>{application.totalPrice} </span> 
                {
                    application.currentTicketData?.lang === LANG_ENG
                    ?
                    '€'
                    :
                    'грн'
                }
            </div>
            </div>

            <div className={styles.infoItem}>
            <div className={styles.key}>
                Дополнительный багаж:
            </div>
            <div className={styles.value}>
                {
                    application.addBaggage
                }
            </div>
            </div>
            <div className={styles.infoItem}>
            <div className={styles.key}>
                Сверхурочный багаж:
            </div>
            <div className={styles.value}>
                {
                    application.addExcessBaggage
                }
            </div>
            </div>
            
        </div>
        <div className={styles.passengers}>
            <div className={styles.key}>
                Пассажиры:
            </div>
            <div className={styles.passengersList}>
            {
                application.passengers.map((passenger,index) => {
                    return (
                        <div 
                        className={styles.passenger}
                        key={index}>
                            <div className={styles.passengerItem}>
                                <div className={styles.passengerKey}>
                                    Имя:
                                </div>
                                <div className={styles.value}>
                                    {passenger.name}
                                </div>
                            </div>
                            <div className={styles.passengerItem}>
                                <div className={styles.passengerKey}>
                                    Фамилия:
                                </div>
                                <div className={styles.value}>
                                    {passenger.surname}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
        {
            !application.isConfirm 
            ?
            <div className={styles.actions}>
                <MainBtn
                onClick={confirmItem}   
                text={'Подтвердить'}
                />
                <MainBtn
                onClick={() => cancelApplication(application)}
                type='red'
                text={'Отменить'}
                />
            </div>
            :
            <></>
        }
        {
            application.isConfirm 
            ?
            dLink
            ?
            <div className={styles.actionBtns}>
            <a 
            target={'_blank'}
            href={dLink}>
                <FiDownload/>
            </a>
            </div>
            :
            <div className={styles.actionBtns}>
                <ActionBtn
                onClick={confirmDownloadTicket}
                text={<FiDownload/>}
                />
            </div>
            :
            <></>
        }
    </div>
  )
}
