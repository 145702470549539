import { createSlice } from '@reduxjs/toolkit'
import { LANG_ENG  } from '../../config/data'

const initialState = {
  lang: LANG_ENG,
}

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLang: (state,action) => {
        state.lang = action.payload
    },
  },
})

export const { changeLang } = langSlice.actions

export default langSlice.reducer