import Admins from "../components/admins/Admins"
import ChartCustom from '../components/chart/ChartCustom'
import styles from '../styles/home.module.scss'

const Home = () => {
  return (
    <div className={'wrapper'}>
        <h1 className={styles.titleMain}>
            Главная
        </h1>
        <div className={styles.body}>
          <Admins/>
        </div>
    </div>
  )
}

export default Home
