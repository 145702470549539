import { useState,useEffect } from 'react'
import { ApplicationItem } from '../applicationItem/ApplicationItem'
import { CustomToggle } from '../../core/customToggle/CustomToggle'
import deleteApplication from '../../services/applications/deleteApplication'
import LoaderCustom from '../../core/loader/Loader'
import getApplications from '../../services/applications/getApplications'
import styles from '../../styles/applications/list.module.scss'

export default function ApplicationList() {
  const [isConfirmedApp,setIsConfirmedApp] = useState(false)
  const [loading,setLoading] = useState(false)
  const [applications,setApplications] = useState([])

  const filterApplication = (application) => {
    setApplications((prev) => {
      return (
        prev.filter((appl) => {
          return appl._id !== application._id
        })
      )
    })
  }

  const cancelApplication = async (application) => {
    setLoading(true)

    const {success} = await deleteApplication(application._id)

    if(success){
      filterApplication(application)
    }

    setLoading(false)
  }



  useEffect(() => {
    setLoading(true)
    getApplications(isConfirmedApp).then(({success,applications}) => {
      setApplications(applications)
      setLoading(false)
    })
  },[isConfirmedApp])
 
  if(loading) return <LoaderCustom/>

  return (
    <div className={styles.body}>
      <div className={styles.head}>
        <CustomToggle
        value={isConfirmedApp}
        handler={() => setIsConfirmedApp((prev) => !prev)}
        firstItem={'Актуальные'}
        secondItem={'Подтвержденные'}
        />
      </div>
      <div className={styles.list}>
        {
          applications.map((appl,index) => {
            return (
              <ApplicationItem 
              key={index}
              application={appl} 
              cancelApplication={cancelApplication}
              filterApplication={filterApplication}
              />
            )
          })
        }
      </div>
    </div>
  )
}
