import styles from '../../styles/buttons/main-btn.module.scss'

const MainButton = ({disabled = false,text,onClick,btnId = 'none',type = ''}) => {
  return (
    <button 
    disabled={disabled}
    id={btnId}
    className={
    type === 'red' 
    ?
    styles.btn + ' ' + styles.red
    :
    styles.btn
    }
    onClick={onClick}>
      {text}
    </button>
  )
}

export default MainButton
