import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import ActionButton from '../../UI/buttons/ActionButton'
import getCurrentLang from '../../utils/getCurrentLang'
import getCurrency from '../../utils/getCurrency'
import styles from '../../styles/tickets/item.module.scss'

const TicketItem = ({ticket,deleteTicket,updateTicket}) => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.btns}>
        <ActionButton
        onClick={() => deleteTicket(ticket)}
        text={<AiOutlineDelete/>}
        />
        <ActionButton
        onClick={() => updateTicket(ticket)}
        text={<AiOutlineEdit/>}
        />
      </div>
      <div className={styles.body}>
      <div className={styles.ticketItem}>
        {ticket.from}
      </div>
      <div className={styles.ticketItem}>
        {ticket.to}
      </div>
      <div className={styles.ticketItem}>
        {ticket.startTime}
      </div>
      <div className={styles.ticketItem}>
        {ticket.endTime}
      </div>
      <div className={styles.ticketItem}>
        {ticket.tripTime}
      </div>
      <div className={styles.ticketItem}>
        {ticket.price}
      </div>
      <div className={styles.ticketItem}>
        {getCurrency(ticket.currency)}
      </div>
      <div className={styles.ticketItem}>
        {
          ticket.isFull
          ?
          'Almost full'
          :
          '-'
        }
      </div>
      <div className={styles.ticketItem}>
        {getCurrentLang(ticket.lang)}
      </div>
      <div className={styles.ticketItem}>
        {ticket.transfer}
      </div>
      <div className={styles.ticketItem}>
        +{ticket.days}
      </div>
      </div>
    </div>
  )
}

export default TicketItem
