import styles from '../../styles/inputs/main-input.module.scss'

export default function MainInput({
    inputId = '',
    label = '',
    type = 'string',
    value = '',
    onChange = () => {},
    name = '',
    placeholder = ''
}) {

  return (
      label
      ?
      <div className={styles.input}>
        <label 
        className={styles.label}
        htmlFor={inputId}>
          {label}
        </label>
        <input
        id={inputId}
        placeholder={placeholder}
        name={name}
        className={styles.body}
        value={value}
        type={type}
        onChange={(e) => onChange(name,e.target.value)}
        />
      </div>
      :
      <input
      placeholder={placeholder}
      name={name}
      className={styles.body}
      value={value}
      type={type}
      onChange={(e) => onChange(name,e.target.value)}
      />

  )
}
