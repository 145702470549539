import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import login from '../services/auth/login'
import MainInput from '../UI/inputs/MainInput'
import MainButton from '../UI/buttons/MainButton'
import inputsHandler from '../utils/inputsHandler'
import LoaderCustom from '../core/loader/Loader'
import styles from '../styles/login.module.scss'

const Login = () => {
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState({username:'',password:''})
    const router = useNavigate()

    const confirmLogin = async () => {
      setLoading(true)
      const {success} = await login(data)
      if(success){
        setLoading(false)
        router('/')
      }
      setLoading(false)
    }

    if(loading) return <LoaderCustom/>

  return (
    <div className={styles.body}>
        <div className={styles.form}>
            <div className={styles.title}>
                FLYBUS
            </div>
            <MainInput
            placeholder='Логин'
            name='username'
            value={data.username}
            onChange={(name,value) => inputsHandler(name,value,setData)}
            />
            <MainInput
            placeholder='********'
            name='password'
            value={data.password}
            type='password'
            onChange={(name,value) => inputsHandler(name,value,setData)}
            />
            <MainButton
            onClick={confirmLogin}
            text={'Авторизоваться'}
            />
        </div>
    </div>
  )
}

export default Login
