import getToken from "../../utils/getToken"
import { API } from "../../config/api"

export default async (id,data) => {
    try{
        const token = getToken()

        const response = await fetch(`${API}/tickets/${id}`,{
            method:'PUT',
            headers:{
                'Content-Type':'application/json',
                'Authorization': `Bearer ${token}`
            },
            body:JSON.stringify(data)
        })

        const {success,ticket} = await response.json()

        return {success,ticket}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}