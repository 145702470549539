import { useState,useEffect, useMemo } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { HiOutlineLockOpen,HiOutlineLockClosed } from "react-icons/hi2";
import activateUser from '../../services/users/activateUser';
import deleteUser from '../../services/users/deleteUser';
import ActionButton from '../../UI/buttons/ActionButton'
import LoaderCustom from '../../core/loader/Loader'
import getUsers from '../../services/users/getUsers'
import CustomSelect from '../../core/customSelect/CustomSelect'
import SearchBar from '../../core/searchBar/SearchBar'
import styles from '../../styles/users/list.module.scss'

const sortValues = [
    {
        name:'Активный',
        isActive:true,
        value:'active',
    },
    {
        name:'Не активный',
        isActive:false,
        value:'notActive',
    }
]

const UsersList = () => {
    const [sortValue,setSortValue] = useState('notActive')
    const [loading,setLoading] = useState(false)
    const [searchValue,setSearchValue] = useState('')
    const [items,setItems] = useState([])
    const [allUsers,setAllUsers] = useState([])

    const sortUsers = (usersList) => {
        const activeUsers = []
        const notActiveUsers = []

        for (let i = 0; i < usersList.length; i++) {
            const user = usersList[i];

            user.isActive
            ?
            activeUsers.push(user)
            :
            notActiveUsers.push(user)
        }

        if(sortValue === 'notActive'){
            return [...notActiveUsers,...activeUsers]
        }else{
            return [...activeUsers,...notActiveUsers]
        }

    }

    const filterUsers = (usersList,filterValue) => {
        return allUsers.filter((user) => user.email.toLowerCase().includes(filterValue.toLowerCase()))
    }

    const confirmActivate = async (email) => {
        setLoading(true)

        const {success} = await activateUser(email)

        if(success){
            const updatedUsers = allUsers.map((user) => {
                if(user.email === email){
                    return {...user,isActive:true}
                }
                return user
            })
            setAllUsers(updatedUsers)
            setItems(updatedUsers)
        }

        setLoading(false)
    }

    const confirmDelete = async (email) => {
        setLoading(true)

        const {success} = await deleteUser(email)

        if(success){
            const updatedUsers = allUsers.filter((user) => {
                return user.email !== email
            })
            setAllUsers(updatedUsers)
            setItems(updatedUsers)
        }

        setLoading(false)
    }
    
    useMemo(() => {
        setItems((prev) => {
            return sortUsers(prev)
        })
    },[sortValue])

    useEffect(() => {
        setLoading(true)
        getUsers().then(({success,users}) => {
            if(success){
                setAllUsers(sortUsers(users))
                setItems(sortUsers(users))
            }
            setLoading(false)
        })
    },[])

    if(loading) return <LoaderCustom/>
   
  return (
    <div className={styles.body}>
        <div className={styles.options}>
            <div className={styles.search}>
                <div className={styles.label}>
                    Поиск по email:
                </div>
                <SearchBar
                value={searchValue}
                onChange={(value) => {
                    setSearchValue(value)
                    setItems(filterUsers(items,value))
                }}
                placeholder={'example@gmail.com'}
                />
            </div>
            <CustomSelect
            name={'Статус'}
            selectedItem={sortValue}
            handler={(value) => setSortValue(value)}
            items={sortValues}
            />
        </div>
      <div className={styles.head}>
        <div className={styles.headItem}>
          Email:
        </div>
        <div className={styles.headItem}>
          Телефон:
        </div>
        <div className={styles.headItem}>
          Статус:
        </div>
      </div>
      <div className={styles.list}>
        {
          items?.map((user,index) => {
            return (
                <div className={styles.itemWrapper} key={index}>
                    <div className={styles.item}>
                        <div className={styles.itemValue}>
                            {
                                user.email
                            }
                        </div>
                        <div className={styles.itemValue}>
                            {
                                user.phone || '-'
                            }
                        </div>
                        <div className={styles.itemValue}>
                            {
                                user.isActive 
                                ?
                                <div className={styles.active}>
                                    Активный
                                </div>
                                :
                                <div className={styles.notActive}>
                                    Не активный
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.btns}>
                        <ActionButton
                        onClick={() => confirmDelete(user.email)}
                        text={<AiOutlineDelete/>}
                        />
                        <ActionButton
                        onClick={() => confirmActivate(user.email)}
                        disabled={user.isActive}
                        text={
                            user.isActive
                            ?
                            <HiOutlineLockOpen/>
                            :
                            <HiOutlineLockClosed/>
                        }
                        />
                    </div>
                </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default UsersList