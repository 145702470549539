import styles from '../../styles/checkbox/checkbox.module.scss'

export default function CustomCheckbox({ isChecked, handler, id = "none" }) {
  const checkboxClass = isChecked
    ? styles.fakeCheckBox + " " + styles.checked
    : styles.fakeCheckBox;

  return (
    <div id={'none'} className={styles.body}>
      <label className={checkboxClass} htmlFor={id}></label>
      <input
        id={id}
        onClick={() => handler((state) => !state)}
        className={styles.checkBox}
        type="checkbox"
      />
    </div>
  );
}
