import { useState,useEffect, useMemo } from "react"
import { useSelector } from 'react-redux'
import { CURRENCY_ENG, CURRENCY_UKR,LANG_RUS,LANG_UKR } from "../../config/data"
import ConfirmModal from '../../core/confirmModal/ConfirmModal'
import CreateTicketModal from "../createTicketModal/CreateTicketModal"
import UpdateTicketModal from "../updateTicketModal/UpdateTicketModal"
import createTicket from "../../services/tickets/createTicket"
import updateTicket from '../../services/tickets/updateTicket'
import deleteTicket from "../../services/tickets/deleteTicket"
import getTickets from "../../services/tickets/getTickets"
import modalHandler from '../../utils/modalHandler.js'
import getCurrency from "../../utils/getCurrency"
import CustomSelect from "../../core/customSelect/CustomSelect"
import SearchBar from "../../core/searchBar/SearchBar"
import TicketItem from "../ticketItem/TicketItem"
import MainButton from "../../UI/buttons/MainButton"
import LoaderCustom from '../../core/loader/Loader'
import styles from '../../styles/tickets/list.module.scss'

const currencyItems = [
  {
    name:'Euro',
    value:'euro',
  },
  {
    name:'Грн',
    value:'grn',
  },
]

const sortItems = [
  {
    name:"От меньшего",
    value:'min',
  },
  {
    name:"От большего",
    value:'max',
  }
]

const TicketsList = () => {
  const lang = useSelector  ((state) => state.lang.lang)
  const [loading,setLoading] = useState(false)
  const [tickets,setTickets] = useState([])

  const [isCreateModal,setIsCreateModal] = useState(false)
  const [isDeleteModal,setIsDeleteModal] = useState(false)
  const [isUpdateModal,setIsUpdateModal] = useState(false)

  const [ticketToDelete,setTicketToDelete] = useState(null)
  const [ticketToUpdate,setTicketToUpdate] = useState(null)

  const [priceSort,setPriceSort] = useState('min')
  const [searchValueFrom,setSearchValueFrom] = useState('')
  const [searchValueTo,setSearchValueTo] = useState('')
  const [currency,setCurrency] = useState(CURRENCY_ENG)
  const [typingTimeout,setTypingTimeout] = useState(0)
  const [isTypingEnd,setIsTypingEnd] = useState(false)

  const validationData = (data) => {
    return (
      !!data.from.length 
      && 
      !!data.to.length 
      &&
      !!data.endTime.length
      &&
      !!data.startTime.length
      &&
      !!data.price
    )  
  }

  const selectTicketToDelete = (ticket) => {
    setTicketToDelete(ticket)
    setIsDeleteModal(true)
  }

  const selectTicketToUpdate = (ticket) => {
    setTicketToUpdate(ticket)
    setIsUpdateModal(true)
  }

  const getTicketsData = async () => {
    setLoading(true)

    const {success,tickets} = 
    await getTickets({lang,currency,priceSort,searchValueFrom,searchValueTo})

    if(success){
      setTickets(tickets)
    }

    setLoading(false)
  }

  const confrimDeleteTicket = async () => {
    setLoading(true)
    const {success} = await deleteTicket(ticketToDelete?._id)

    if(success){
      setTickets((prev) => {
        return (
          prev.filter((ticket) => ticket?._id !== ticketToDelete?._id)
        )
      })
    }
    setLoading(false)
    setIsDeleteModal(false)
  }

  const confirmUpdateTicket = async (data) => {
    setLoading(true)

    const {success,ticket} = await updateTicket(data?._id,data)

    if(success){
      setTickets((prev) => {
        return (
          prev.map((item) => {
            if(item._id === ticket._id){
              return data
            }

            return item
          })
        )
      })
    }

    setLoading(false)
    setIsUpdateModal(false)
  }

  const addTicket = async (ticketData) => {
    const isValid = validationData(ticketData)

    if(!isValid) return

    setLoading(true)
    const {success,ticket} = await createTicket(ticketData)

    if(success){
      setTickets((prev) => {
        return (
          [ticket,...prev]
        )
      })
    }

    setLoading(false)
    setIsCreateModal(false)
  }

  const handleInputChange = (text,setInputValue) => {
    setInputValue(text);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setIsTypingEnd((prev) => !prev)
      }, 1000)
    );
  };

  useEffect(() => {
    getTicketsData()
  },[currency,lang,priceSort,isTypingEnd])

  useMemo(() => {
    setCurrency(
      lang === LANG_UKR || lang === LANG_RUS
      ?
      CURRENCY_UKR
      :
      CURRENCY_ENG
    )
  },[lang])
  
  if(loading) return <LoaderCustom/>
  
  return (
    <>
    <div className={styles.wrapper}>
      <div className={styles.btns}>
        <div className={styles.btnsWrapper}>
        <SearchBar
        placeholder={'Откуда'}
        value={searchValueFrom}
        onChange={(value) => handleInputChange(value,setSearchValueFrom)}
        />
        <SearchBar
        placeholder={'Куда'}
        value={searchValueTo}
        onChange={(value) => handleInputChange(value,setSearchValueTo)}
        />
        </div>
        <div className={styles.btnsWrapper}>
        <CustomSelect
        handler={(value) => setCurrency(value)}
        name={`Валюта (${getCurrency(currency)})`}
        selectedItem={currency}
        items={currencyItems}
        />
        <CustomSelect
        handler={(value) => setPriceSort(value)}
        name={`Цена (${priceSort})`}
        selectedItem={priceSort}
        items={sortItems}
        />
        </div>
        <div className={styles.addBtn}>
          <MainButton
          onClick={() => setIsCreateModal(true)}
          text={'Добавить'}
          />
        </div>
      </div>
      <div className={styles.body}>
      <div className={styles.head}>
        <div className={styles.headItem}>
          Откуда:
        </div>
        <div className={styles.headItem}>
          Куда:
        </div>
        <div className={styles.headItem}>
          Отправка:
        </div>
        <div className={styles.headItem}>
          Прибытие:
        </div>
        <div className={styles.headItem}>
          Время в пути:
        </div>
        <div className={styles.headItem}>
          Цена :
        </div>
        <div className={styles.headItem}>
          Валюта:
        </div>
        <div className={styles.headItem}>
          Заполненный:
        </div>
        <div className={styles.headItem}>
          Язык:
        </div>
        <div className={styles.headItem}>
          Пересадки:
        </div>
        <div className={styles.headItem}>
          Кол-во дней:
        </div>
      </div>
      <div className={styles.list}>
      {
        tickets.map((ticket,index) => {
          return (
            <TicketItem
            updateTicket={selectTicketToUpdate}
            deleteTicket={selectTicketToDelete}
            ticket={ticket}
            key={index}
            />
          )
        })
      }
      </div>
      </div>
    </div>
    <UpdateTicketModal
    onConfirm={confirmUpdateTicket}
    id={ticketToUpdate?._id}
    isVisible={isUpdateModal}
    modalHandler={(e) => modalHandler(e,setIsUpdateModal)}
    />
    <CreateTicketModal
    onConfirm={addTicket}
    isVisible={isCreateModal}
    modalHandler={(e) => modalHandler(e,setIsCreateModal)}
    />
    <ConfirmModal
    title={`Удаление билета ${ticketToDelete?.from} - ${ticketToDelete?.to}`}
    isVisible={isDeleteModal}
    modalHandler={(e) => modalHandler(e,setIsDeleteModal)}
    onClick={confrimDeleteTicket}
    />
    </>
  )
}

export default TicketsList
