import {useDispatch,useSelector} from 'react-redux'
import {useLocation,Link} from 'react-router-dom'
import { IoExitOutline } from 'react-icons/io5'
import { changeLang } from '../../store/slices/langSlice'
import { links } from './Nav'
import { LANG_ENG, LANG_RUS, LANG_UKR } from '../../config/data'
import styles from '../../styles/layout/mobile.module.scss'

const MobileNav = ({isVisible,handler,logout}) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const currentLang = useSelector((state) => state.lang.lang)

  return (
    <div 
    onClick={handler}
    id='toggle-modal'
    className={
        isVisible
        ?
        styles.wrapper + ' ' + styles.modalVisible
        :
        styles.wrapper
        
    }>
        <nav className={
            isVisible
            ?
            styles.body + ' ' + styles.modalBodyVisible
            :
            styles.body
            }>
            <div className={styles.head}>
                <div className={styles.logo}>
                    <a href='#'>
                        FLYBUS
                    </a>
                </div>
                <div className={styles.label}>
                    Админ панель
                </div>
            </div>
            <div className={styles.links}>
            {
                links.map((link,index) => {
                    return (
                        <Link 
                        id='toggle-modal'
                        key={index}
                        className={
                            location.pathname === link.href
                            ?
                            styles.current
                            :
                            styles.link
                        } to={link.href}>
                            {link.icon}
                            {link.name}
                        </Link>
                    )
                })
            }
            </div>
            <div className={styles.actions}>
                <button
                onClick={logout}
                className={styles.logout}>
                    <IoExitOutline/>
                </button>
            </div>
            <div className={styles.langs}>
            <button
            onClick={() => dispatch(changeLang(LANG_ENG))}
            className={
              currentLang === LANG_ENG
              ?
              styles.lang + ' ' + styles.current 
              :
              styles.lang
            }
            >
              АНГЛ
            </button>
            <span>|</span>
            <button
            onClick={() => dispatch(changeLang(LANG_UKR))}
            className={
              currentLang === LANG_UKR
              ?
              styles.lang + ' ' + styles.current 
              :
              styles.lang
            }
            >
              УКР
            </button>
          </div>
        </nav>
    </div>
  )
}

export default MobileNav
