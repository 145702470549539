import { useState } from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'
import styles from '../../styles/core/custom-select.module.scss'

export default function CustomSelect({name,items,selectedItem,handler}) {
    const [isOpen,setIsOpen] = useState(false)

    const confirmSelect = (value) => {
        handler(value)
        setIsOpen(false)
    }

  return (
    <div className={styles.body}>
        <button 
        onClick={() => setIsOpen((prev) => !prev)}
        className={
            isOpen
            ?
            styles.selectBtn + ' ' + styles.open 
            :
            styles.selectBtn
        }>
            <span>{name}</span>
            <AiOutlineArrowDown/>
        </button>
        <div className={
            isOpen
            ?
            styles.list + " " + styles.visible 
            :
            styles.list
        }>
            {
                items.map((item,index) => {
                    return (
                        <button
                        onClick={() => confirmSelect(item.value)}
                        key={index}
                        className={
                            item.value === selectedItem
                            ?
                            styles.item + ' ' + styles.selected
                            :
                            styles.item
                        }>
                            {item.name}
                        </button>
                    )
                })
            }
        </div>
    </div>
  )
}
