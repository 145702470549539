import getToken from "../../utils/getToken"
import { API } from "../../config/api"

export default async (id) => {
    try{
        const response = await fetch(`${API}/tickets/${id}`)

        const {success,ticket} = await response.json()

        return {success,ticket}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}