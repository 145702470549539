import loader from '../../config/loader'
import styles from '../../styles/core/file-input.module.scss'

export default function FileInput({name,handler,file,label}) {
  const fileHandler = (event) => {
    event.preventDefault()
    if (event.target.files[0]) {
      handler(name,event.target.files[0]);
    }
  }

  return (
    label
    ?
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {label}
      </div>
      <div className={styles.inputWrapper}>
        <div className={styles.fakeInput}>
          <label htmlFor='file-input' className={styles.fake}>{file ? file.name : '+ Add file'}</label>
          <input 
          onChange={fileHandler} 
          type="file" 
          id={'file-input'} />
        </div>
        <div className={styles.imgView}>
          {
            file 
            ?
            <img 
            className={styles.img}
            src={
              typeof file === 'string'
              ?
              loader(file)
              :
              URL.createObjectURL(file)
            } 
            alt="img" />
            :
            <></>
          }
        </div>
      </div>
    </div>
    :
    <div className={styles.fakeInput}>
        <label htmlFor='file-input' className={styles.fake}>{file ? file.name : '+ Add file'}</label>
        <input 
        onChange={fileHandler} 
        type="file" 
        id={'file-input'} />
    </div>
  )
}
