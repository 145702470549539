import { API } from "../../config/api"
import getToken from "../../utils/getToken"

export default async () => {
    try{
        const token = getToken()

        const response = await fetch(`${API}/users`,{
            headers:{
                'Authorization': `Bearer ${token}`
            }
        })

        const {success,users} = await response.json()

        return {success,users}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}