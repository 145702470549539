import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isAuth: false,
  admin:{}
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAdmin(state,action){
      state.admin = action.payload
    },
    login: (state,action) => {
    },
    logout: (state,action) => {
    },
  },
})

export const { login, logout, setAdmin} = authSlice.actions

export default authSlice.reducer