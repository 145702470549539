import { useState } from 'react'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import CustomCheckbox from '../../UI/checkbox/CustomCheckbox'
import getCurrency from '../../utils/getCurrency'
import Modal from '../../core/Modal/Modal'
import MainInput from '../../UI/inputs/MainInput'
import CustomSelect from '../../core/customSelect/CustomSelect'
import MainButton from '../../UI/buttons/MainButton'
import inputsHandler from '../../utils/inputsHandler'
import firstLetterUpperCase from '../../utils/firstLetterUpperCase'
import styles from '../../styles/tickets/create.module.scss'

const inputs = [
    {
        name:'from',
        placeholder:'Kyiv (bus station)',
        label:'Город: (Откуда *)',
        type:'string'
    },
    {
        name:'to',
        placeholder:'Kharkiv (bus station)',
        label:'Город: (Куда *)',
        type:'string'
    },
    {
        name:'fromAddress',
        placeholder:'Bus station',
        label:'Адрес: (Откуда *)',
        type:'string'
    },
    {
        name:'toAddress',
        placeholder:'Bus station',
        label:'Адрес: (Куда *)',
        type:'string'
    },
    {
        name:'countryFrom',
        placeholder:'Ukraine',
        label:'Странна: (Откуда *)',
        type:'string'
    },
    {
        name:'countryTo',
        placeholder:'Ukraine',
        label:'Странна: (Куда *)',
        type:'string'
    },
    {
        name:'startTime',
        placeholder:'19:10',
        label:'Время отправки: *',
        type:'string'
    },
    {
        name:'endTime',
        placeholder:'20:10',
        label:'Время прибытия: *',
        type:'string'
    },
    {
        name:'tripTime',
        placeholder:'20:10 hrs',
        label:'Время в пути:',
        type:'string'
    },
    {
        name:'price',
        placeholder:'200',
        label:'Цена: *',
        type:'number'
    },
    {
        name:'transfer',
        placeholder:'1 transfer',
        label:'Пересадки:',
        type:'string'
    },
    {
        name:'days',
        placeholder:'1',
        label:'Кол-во дней в пути',
        type:'number'
    },
    {
        name:'places',
        placeholder:'1',
        label:'Место осталось',
        type:'number'
    },
]

const inputsUa = [
    {
        name:'from',
        placeholder:'Київ',
        label:'Город: (Откуда *)',
        type:'string'
    },
    {
        name:'to',
        placeholder:'Харків',
        label:'Город: (Куда *)',
        type:'string'
    },
    {
        name:'fromAddress',
        placeholder:'Bus station',
        label:'Адрес: (Откуда *)',
        type:'string'
    },
    {
        name:'toAddress',
        placeholder:'Bus station',
        label:'Адрес: (Куда *)',
        type:'string'
    },
    {
        name:'countryFrom',
        placeholder:'Ukraine',
        label:'Странна: (Откуда *)',
        type:'string'
    },
    {
        name:'countryTo',
        placeholder:'Ukraine',
        label:'Странна: (Куда *)',
        type:'string'
    },
    {
        name:'startTime',
        placeholder:'19:10',
        label:'Время отправки: *',
        type:'string'
    },
    {
        name:'endTime',
        placeholder:'20:10',
        label:'Время прибытия: *',
        type:'string'
    },
    {
        name:'tripTime',
        placeholder:'20:10 год',
        label:'Время в пути:',
        type:'string'
    },
    {
        name:'price',
        placeholder:'200',
        label:'Цена: *',
        type:'number'
    },
    {
        name:'transfer',
        placeholder:'1 пересадка',
        label:'Пересадки:',
        type:'string'
    },
    {
        name:'days',
        placeholder:'1',
        label:'Кол-во дней в пути',
        type:'number'
    },
    {
        name:'places',
        placeholder:'1',
        label:'Место осталось',
        type:'number'
    },
]

const inputsRus = [
    {
        name:'from',
        placeholder:'Київ',
        label:'Город: (Откуда *)',
        type:'string'
    },
    {
        name:'to',
        placeholder:'Харків',
        label:'Город: (Куда *)',
        type:'string'
    },
    {
        name:'fromAddress',
        placeholder:'Bus station',
        label:'Адрес: (Откуда *)',
        type:'string'
    },
    {
        name:'toAddress',
        placeholder:'Bus station',
        label:'Адрес: (Куда *)',
        type:'string'
    },
    {
        name:'countryFrom',
        placeholder:'Ukraine',
        label:'Странна: (Откуда *)',
        type:'string'
    },
    {
        name:'countryTo',
        placeholder:'Ukraine',
        label:'Странна: (Куда *)',
        type:'string'
    },
    {
        name:'startTime',
        placeholder:'19:10',
        label:'Время отправки: *',
        type:'string'
    },
    {
        name:'endTime',
        placeholder:'20:10',
        label:'Время прибытия: *',
        type:'string'
    },
    {
        name:'tripTime',
        placeholder:'20:10 год',
        label:'Время в пути:',
        type:'string'
    },
    {
        name:'price',
        placeholder:'200',
        label:'Цена: *',
        type:'number'
    },
    {
        name:'transfer',
        placeholder:'1 пересадка',
        label:'Пересадки:',
        type:'string'
    },
    {
        name:'days',
        placeholder:'1',
        label:'Кол-во дней в пути',
        type:'number'
    },
    {
        name:'places',
        placeholder:'1',
        label:'Место осталось',
        type:'number'
    },
]

const currentInputs = {
    'eng':inputs,
    'ukr':inputsUa,
    'rus':inputsRus
}

const currencyItems = [
    {
      name:'Euro',
      value:'euro',
    },
    {
      name:'Грн',
      value:'grn',
    },
]

const CreateTicketModal = ({isVisible,modalHandler,onConfirm}) => {
    const [isFull,setIsFull] = useState(false)
    const [currency,setCurrency] = useState('euro')
    const [data,setData] = useState({
        from:'',
        to:'',
        startTime:'',
        endTime:'',
        tripTime:'',
        price:0,
        transfer:'',
        days:1,
        country:'',
        longitudeFrom:0,
        latitudeFrom:0,
        longitudeTo:0,
        latitudeTo:0,
        coordinates:{
            from:'',
            to:''
        }
    })
    const lang = useSelector((state) => state.lang.lang)

    const coordiantesHandler = (name,value) => {
        if(!value.includes(',')){
            setData((prev) => {
                return (
                    {
                        ...prev,
                        coordinates:{...prev.coordinates,[name]:''}
                    }
                )
            })
            return
        }
        const long = Number(value.split(',')[0].trim())
        const lati = Number(value.split(',')[1].trim())

        const inputNameLong = 'latitude' + firstLetterUpperCase(name)
        const inputNameLati = 'longitude' + firstLetterUpperCase(name)
   
        setData((prev) => {
            return (
                {
                    ...prev,
                    [inputNameLong]:long,
                    [inputNameLati]:lati,
                    coordinates:{...prev.coordinates,[name]:value}
                }
            )
        })
    }

  return (
    <Modal
    bodyClass={'ticket-modal'}
    overflowY='auto'
    width='1300'
    title={`Создать билет (${lang})`}
    isVisible={isVisible}
    handler={modalHandler}
    >
        <div className={styles.body}>
            <div className={styles.inputs}>
                {
                    currentInputs[lang].map((input,index) => {
                        return (
                            <MainInput
                            type={input.type}
                            placeholder={input.placeholder}
                            name={input.name}
                            label={input.label}
                            key={index}
                            value={data[input.name]}
                            onChange={(name,value) => inputsHandler(name,value,setData)}
                            />
                        )
                    })
                }

                <div className={styles.currencyInput}>
                    <div className={styles.currencyLabel}>
                        Валюта:
                    </div>
                    <CustomSelect
                    handler={(value) => setCurrency(value)}
                    selectedItem={currency}
                    name={`Валюта (${getCurrency(currency)})`}
                    items={currencyItems}
                    />
                </div>
                <div className={styles.currencyInput}>
                    <div className={styles.currencyLabel}>
                        Заполненный:
                    </div>
                    <CustomCheckbox
                    handler={() => setIsFull((prev) => !prev)}
                    isChecked={isFull}
                    />
                </div>
            </div>
            <div className={styles.coordinates}>
                    <MainInput
                    placeholder={'49.83683864881104, 24.01248988041225'}
                    name={'from'}
                    label={'Долгота - Широта (первый город - откуда)'}
                    value={data.coordinates.from}
                    onChange={(name,value) => coordiantesHandler(name,value)}
                    />
                    <MainInput
                    placeholder={'49.83683864881104, 24.01248988041225'}
                    name={'to'}
                    label={'Долгота - Широта (второй город - куда)'}
                    value={data.coordinates.to}
                    onChange={(name,value) => coordiantesHandler(name,value)}
                    />
                </div>
            <div className={styles.btns}>
                <MainButton
                btnId='toggle-modal'
                type='red'
                text={'Отменить'}
                />
                <MainButton
                onClick={() => onConfirm({...data,lang,currency,isFull})}
                text={'Подтвердить'}
                />
            </div>
        </div>
    </Modal>
  )
}

export default CreateTicketModal
