import { ColorRing } from  'react-loader-spinner'
import styles from '../../styles/core/loader.module.scss'

const LoaderCustom = () => {
    return (
        <div className={styles.modal}>
            <ColorRing
              visible={true}
              height="150"
              width="150"
              wrapperClass="blocks-wrapper"
              colors={['#5e1acb', '#5e1acb', '#5e1acb', '#5e1acb', '#5e1acb']}
            />
        </div>
    );
}

export default LoaderCustom;
