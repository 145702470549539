import { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import { MdKeyboardArrowDown,MdKeyboardArrowUp } from "react-icons/md";
import updateInfo from "../services/info/updateInfo";
import getInfo from "../services/info/getInfo";
import inputsHandler from "../utils/inputsHandler"
import MainInput from "../UI/inputs/MainInput"
import MainButton from "../UI/buttons/MainButton"
import TextEditor from "../core/textEditor/TextEditor"
import Reviews from "../components/reviews/Reviews"
import Loader from '../core/loader/Loader'
import styles from '../styles/content/content.module.scss'

const Content = () => {
    const {lang} = useSelector((state) => state.lang)
    const [contentVisibile,setContentVisible] = useState({
        socialMedia:false,
        reviews:false,
        texts:false,
    })
    const [loading,setLoading] = useState(false)
    const [data,setData] = useState(() => (
        {
            main:"",
            legal:'',
            policy:'',
            credits:'',
            telegram:'',
            whatsapp:'',
            email:'',
        }
    ))

    const confirmUpdateInfo = async () => {
        setLoading(true)
        const currentLangData = {}

        const keys = [
            `main${lang}`,
            `legal${lang}`,
            `policy${lang}`,
            `credits${lang}`,
        ]

        for (let i = 0; i < keys.length; i++) {
            const currentLangKey = keys[i];
            const key = currentLangKey.split(lang)[0]

            currentLangData[currentLangKey] = data[key]
            
        }

        currentLangData.email = data.email
        currentLangData.telegram = data.telegram
        currentLangData.whatsapp = data.whatsapp

        const {success,info} = await updateInfo(currentLangData)

        setLoading(false)
    }

    const contentItemsHandler = (name) => {
        setContentVisible((prev) => {
            return (
                {
                    ...prev,
                    [name]:!prev[name]
                }
            )
        })
    }

    useEffect(() => {
        setLoading(true)
        getInfo().then(({success,info}) => {
            if(!success) {
                setLoading(false)
                return
            } 

            const currentLangData = {}
  
            for (const key in info) {
                if(key.includes(lang)){
                    const currentKey = key.split(lang)[0]

                    currentLangData[currentKey] = info[key]
                }
            }
  
            currentLangData.email = info.email
            currentLangData.telegram = info.telegram
            currentLangData.whatsapp = info.whatsapp

            setData(currentLangData)
            setLoading(false)
        })
    },[lang])

    if(loading) return <Loader/>

  return (
    <div className={"wrapper" + ' ' + styles.body}>
        <h1>
            Контент
        </h1>
        <div className={styles.contentItem}>
            <button 
            onClick={() => contentItemsHandler('socialMedia')}
            className={styles.contentItemBtn}>
                <span>Социальные сети</span>
                {
                    contentVisibile.socialMedia
                    ?
                    <MdKeyboardArrowUp/>
                    :
                    <MdKeyboardArrowDown/>
                }
            </button>
            {
                contentVisibile.socialMedia
                ?
                <div className={styles.socialMedia}>
                    <MainInput
                    name="telegram"
                    label="Ссылка на телеграм:"
                    value={data.telegram}
                    placeholder="https://t.me/test"
                    onChange={(name,value) => inputsHandler(name,value,setData)}
                    />
                    <MainInput
                    name="whatsapp"
                    label="Ссылка на whatsapp:"
                    value={data.whatsapp}
                    placeholder="https://wa.me/093212345"
                    onChange={(name,value) => inputsHandler(name,value,setData)}
                    />
                    <MainInput
                    name="email"
                    label="Email:"
                    value={data.email}
                    placeholder="test@gmail.com"
                    onChange={(name,value) => inputsHandler(name,value,setData)}
                    />
                    <div className={styles.saveBtn}>
                        <MainButton
                        onClick={confirmUpdateInfo}
                        text={'Сохранить'}
                        />
                    </div>
                </div>
                :
                <></>
            }
        </div>

        <div className={styles.contentItem}>
            <button 
            onClick={() => contentItemsHandler('reviews')}
            className={styles.contentItemBtn}>
                <span>Отзывы</span>
                {
                    contentVisibile.reviews
                    ?
                    <MdKeyboardArrowUp/>
                    :
                    <MdKeyboardArrowDown/>
                }
            </button>
            {
            contentVisibile.reviews
            ?
            <Reviews
            />
            :
            <></>
            }

        </div>

        <div className={styles.contentItem}>
            <button 
            onClick={() => contentItemsHandler('texts')}
            className={styles.contentItemBtn}>
                <span>Тексты</span>
                {
                    contentVisibile.texts
                    ?
                    <MdKeyboardArrowUp/>
                    :
                    <MdKeyboardArrowDown/>
                }
            </button>
            {
                contentVisibile.texts
                ?
                <div className={styles.editors}>
                    <div className={styles.editor}>
                        <TextEditor
                        value={data.main}
                        name={'main'}
                        handler={(name,value) => inputsHandler(name,value,setData)}
                        title={'Текст на главной странице:'}
                        />
                        <div className={styles.editorBtn}>
                            <MainButton
                            text={'Сохранить'}
                            onClick={confirmUpdateInfo}
                            />
                        </div>
                    </div>
                    <div className={styles.editor}>
                        <TextEditor
                        value={data.legal}
                        name={'legal'}
                        handler={(name,value) => inputsHandler(name,value,setData)}
                        title={'Политика конфиденциальности:'}
                        />
                        <div className={styles.editorBtn}>
                            <MainButton
                            text={'Сохранить'}
                            onClick={confirmUpdateInfo}
                            />
                        </div>
                    </div>
                    <div className={styles.editor}>
                        <TextEditor
                        value={data.policy}
                        name={'policy'}
                        handler={(name,value) => inputsHandler(name,value,setData)}
                        title={'Права пасажира:'}
                        />
                        <div className={styles.editorBtn}>
                            <MainButton
                            text={'Сохранить'}
                            onClick={confirmUpdateInfo}
                            />
                        </div>
                    </div>
                    <div className={styles.editor}>
                        <TextEditor
                        value={data.credits}
                        name={'credits'}
                        handler={(name,value) => inputsHandler(name,value,setData)}
                        title={'Юридическая информация:'}
                        />
                        <div className={styles.editorBtn}>
                            <MainButton
                            text={'Сохранить'}
                            onClick={confirmUpdateInfo}
                            />
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </div>


    </div>
  )
}

export default Content
