import getToken from "../../utils/getToken"
import { API } from "../../config/api"

export default async (id) => {
    try{
        const token = getToken()

        const response = await fetch(`${API}/applications/${id}`,{
            method:'DELETE',
            headers:{
                'Authorization': `Bearer ${token}`
            },
        })

        const {success,application} = await response.json()

        return {success,application}
    }catch(error){
        console.log(error)

        return {success:false}
    }
}